import React, { useEffect, useState } from 'react';
import { Box, Typography, Chip, IconButton, TextareaAutosize, Divider } from "@material-ui/core";
import { IconPlus } from '@paro.io/base-icons';
import { getFreelancerDetailsFn } from '../../utils/platformService';
import { getAllFreelancerRatingsAndReviews } from '../../services/apiService';
import FreelancerSection from './FreelancerSection';
import CachedIcon from '@material-ui/icons/Cached';
import { Button } from "@paro.io/base-ui";
import SendIcon from '@material-ui/icons/Send';


const ChatScreen = ({ fieldText, generatedSummary, setGeneratedSummary, activeStage, setActiveStage, handleNext, isParoWebsite }) => {

    const [loadingForm, setLoadingForm] = useState(true);
    const [freelancers, setFreelancers] = useState();
    const [loadingFls, setLoadingFls] = useState(false);
    const [showSummary, setShowSummary] = useState(false);
    const [userInfo, setUserInfo] = useState({
        name: '',
        businessName: '',
        email: ''
    });
    const [currentQuestion, setCurrentQuestion] = useState(null);
    const [showUserForm, setShowUserForm] = useState(false);
    const [currentInput, setCurrentInput] = useState('');

    useEffect(() => {
        if (activeStage === 0) {
            const data = sessionStorage.getItem("enh-proposal");
            if (data) {
                setGeneratedSummary(JSON.parse(data).generatedSummary);
            }
        }
    }, [activeStage, setGeneratedSummary]);

    useEffect(() => {
        const clearSessionStorage = () => {
            sessionStorage.clear();
        };

        window.addEventListener("beforeunload", clearSessionStorage);

        return () => {
            window.removeEventListener("beforeunload", clearSessionStorage);
        };
    }, []);

    useEffect(() => {
        const freelancersData = sessionStorage.getItem("enh-freelancers");
        if (freelancersData) {
            setFreelancers(JSON.parse(freelancersData));
        }
        setLoadingForm(false);
    }, []);

    useEffect(() => {
        const runMatch = async () => {
            await onRunMatch({
                notes: generatedSummary["Proposal Description"],
                software: generatedSummary["Required Software"],
                fasttrackServices:
                    generatedSummary["Required Services"]?.join(", "),
            });
        };

        if (!loadingForm && !freelancers) {
            runMatch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingForm]);

    const getClientRatings = async (flID) => {
        const res = await getAllFreelancerRatingsAndReviews(flID);
        const ratings = res.data.data.getAllFreelancerRatingsAndReviews;

        const overallRatingSum = ratings.reduce(
            (sum, rating) => sum + rating.overallRating,
            0
        );

        const overallRatingAvg =
            ratings.length > 0 ? overallRatingSum / ratings.length : 0;

        return { ratings, overallRatingAvg };
    };

    const onRunMatch = async (payload) => {
        setLoadingFls(true);
        try {
            const response = await fetch(
                "https://dq7fke59ni.execute-api.us-east-1.amazonaws.com/dev3/proposal_demo",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(payload),
                }
            );

            const algoResponse = await response.json();

            algoResponse.recommendedFreeLancers = JSON.parse(
                algoResponse.recommendedFreeLancers
            )
                .sort((a, b) => b.score - a.score)
                .slice(0, 5)
                .map((fl) => ({ ...fl, flID: fl.freelancer_id }));

            const freelancers = await getFreelancerDetailsFn(algoResponse);

            for (let fl of freelancers) {
                const { ratings, overallRatingAvg } = await getClientRatings(fl.flID);
                fl.ratings = ratings;
                fl.overallRatingAvg = overallRatingAvg;
            }

            setFreelancers(freelancers);
            sessionStorage.setItem("enh-freelancers", JSON.stringify(freelancers));
        } catch (error) {
            console.error("Error:", error);
        }
        setLoadingFls(false);
    };

    const handleMatch = () => {
        const payload = {
            notes: generatedSummary["Proposal Description"],
            software:
                generatedSummary["Required Software"]?.map((software) => software.value).join(", ") || "",
            fasttrackServices: generatedSummary["Required Services"]?.join(", ") || "",
        };
        onRunMatch(payload);
        setShowSummary(true);
    };

    const handleChipClick = () => {
        setShowUserForm(true);
        setCurrentQuestion('name');
    };

    const handleSubmit = () => {
        if (currentInput.trim()) {
            setUserInfo(prev => ({
                ...prev,
                [currentQuestion]: currentInput.trim()
            }));

            if (currentQuestion === 'name') {
                setCurrentQuestion('businessName');
            } else if (currentQuestion === 'businessName') {
                setCurrentQuestion('email');
            } else if (currentQuestion === 'email') {
                setShowSummary(true);
                setShowUserForm(false);
            }
            setCurrentInput('');
        }
    };

    const renderQuestion = () => {
        switch (currentQuestion) {
            case 'name':
                return "Before we proceed, can we have your name?";
            case 'businessName':
                return "what’s the name of your business?";
            case 'email':
                return "Could you share your email so we can follow up if needed?";
            default:
                return null;
        }
    };


    return (
        <Box>
            <Box style={{ borderBottom: '3px solid #2ca01c', paddingBottom: '6px', width: '100%' }} m={2}>
                <Typography variant="h4" align="left">Here's what you're telling us you need</Typography>
            </Box>

            {/* Original user input */}
            <Box style={{ width: '40%', maxWidth: '80%', marginLeft: 'auto', backgroundColor: '#7CB5B5', borderRadius: '12px' }} p={1} m={2}>
                <Typography variant="body1" align="left">{fieldText}</Typography>
            </Box>

            {/* Service options with avatar */}
            {!showUserForm && !showSummary && (
                <>
                    <Box style={{ display: 'flex', gap: '12px', margin: '8px' }}>
                        <Box style={{ marginTop: '8px' }}>
                            {isParoWebsite ? (
                                <img
                                    src="https://expert-files-dev.s3.us-east-1.amazonaws.com/ParoGroupLogo.png"
                                    style={{ height: 40, width: 40, borderRadius: '50%' }}
                                    alt="Paro logo"
                                />
                            ) : (
                                <img
                                    src="data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 36 36'%3e%3cpath fill='%232CA01C' d='M18 36c9.9411 0 18-8.0589 18-18 0-9.94114-8.0589-18-18-18C8.05886 0 0 8.05886 0 18c0 9.9411 8.05886 18 18 18Z'/%3e%3cpath fill='white' d='M12.0002 11c-3.86797 0-6.99996 3.136-6.99996 7 0 3.868 3.13199 7 6.99996 7h1.0001v-2.6h-1.0001c-2.42798 0-4.39995-1.972-4.39995-4.4 0-2.428 1.97197-4.4 4.39995-4.4h2.4041v13.6c0 1.436 1.1639 2.6 2.5999 2.6V11h-5.004ZM24.0038 25c3.868 0 6.9999-3.1361 6.9999-7 0-3.868-3.1319-7-6.9999-7h-1.0001v2.5999h1.0001c2.428 0 4.3999 1.9721 4.3999 4.4001s-1.9719 4.3999-4.3999 4.3999h-2.4041V8.79997c0-1.43602-1.1639-2.60002-2.5999-2.60002V25h5.004Z'/%3e%3c/svg%3e"
                                    alt="QuickBooks"
                                    style={{ height: 40, width: 40 }}
                                />
                            )}
                        </Box>
                        <Box style={{ flex: 1, borderRadius: '12px', border: '2px solid #CBD5E1' }} p={2} m={1}>
                            <Typography variant='body1' style={{ color: "#0B6A6B" }}>
                                *Based on your original input, we think you want one of these services, if not restart
                            </Typography>
                            <Box mt={1}>
                                {generatedSummary["Required Services"]?.map((obj) => (
                                    <Chip
                                        key={obj}
                                        label={
                                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                                {obj}
                                                <span style={{ marginLeft: '8px', color: '#248384' }}>
                                                    <IconPlus size="xs" />
                                                </span>
                                            </span>
                                        }
                                        style={{ border: '2px solid #248384', color: '#060821', backgroundColor: '#EEFFFF', margin: '2px' }}
                                        onClick={handleChipClick}
                                    />
                                ))}
                                <IconButton onClick={handleMatch}><CachedIcon style={{ color: '#248384' }} /></IconButton>
                            </Box>
                        </Box>
                    </Box>
                </>
            )}

            {/* Questions and answers with individual avatars */}
            {userInfo.name && (
                <>
                    <Box style={{ display: 'flex', gap: '12px', margin: '16px' }}>
                        <Box style={{ marginTop: '8px' }}>
                            {isParoWebsite ? (
                                <img src="https://expert-files-dev.s3.us-east-1.amazonaws.com/ParoGroupLogo.png" style={{ height: 40, width: 40, borderRadius: '50%' }} alt="Paro logo" />
                            ) : (
                                <img
                                    src="data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 36 36'%3e%3cpath fill='%232CA01C' d='M18 36c9.9411 0 18-8.0589 18-18 0-9.94114-8.0589-18-18-18C8.05886 0 0 8.05886 0 18c0 9.9411 8.05886 18 18 18Z'/%3e%3cpath fill='white' d='M12.0002 11c-3.86797 0-6.99996 3.136-6.99996 7 0 3.868 3.13199 7 6.99996 7h1.0001v-2.6h-1.0001c-2.42798 0-4.39995-1.972-4.39995-4.4 0-2.428 1.97197-4.4 4.39995-4.4h2.4041v13.6c0 1.436 1.1639 2.6 2.5999 2.6V11h-5.004ZM24.0038 25c3.868 0 6.9999-3.1361 6.9999-7 0-3.868-3.1319-7-6.9999-7h-1.0001v2.5999h1.0001c2.428 0 4.3999 1.9721 4.3999 4.4001s-1.9719 4.3999-4.3999 4.3999h-2.4041V8.79997c0-1.43602-1.1639-2.60002-2.5999-2.60002V25h5.004Z'/%3e%3c/svg%3e"
                                    alt="QuickBooks"
                                    style={{ height: 40, width: 40 }}
                                />
                            )}
                        </Box>
                        <Box style={{ backgroundColor: '#F9FFFF', borderRadius: '12px', padding: '12px 16px' }}>
                            <Typography>Before we proceed, can we have your name?</Typography>
                        </Box>
                    </Box>
                    <Box style={{ width: '30%', maxWidth: '80%', marginLeft: 'auto', backgroundColor: '#7CB5B5', borderRadius: '12px' }} p={1} m={2}>
                        <Typography variant="body1" align="left">{userInfo.name}</Typography>
                    </Box>
                </>
            )}

            {/* Repeat similar structure for businessName and email */}
            {userInfo.businessName && (
                // Similar structure as name, with question and answer
                <>
                    <Box style={{ display: 'flex', gap: '12px', margin: '16px' }}>
                        <Box style={{ marginTop: '8px' }}>
                            {isParoWebsite ? (
                                <img src="https://expert-files-dev.s3.us-east-1.amazonaws.com/ParoGroupLogo.png" style={{ height: 40, width: 40, borderRadius: '50%' }} alt="Paro logo" />
                            ) : (
                                <img
                                    src="data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 36 36'%3e%3cpath fill='%232CA01C' d='M18 36c9.9411 0 18-8.0589 18-18 0-9.94114-8.0589-18-18-18C8.05886 0 0 8.05886 0 18c0 9.9411 8.05886 18 18 18Z'/%3e%3cpath fill='white' d='M12.0002 11c-3.86797 0-6.99996 3.136-6.99996 7 0 3.868 3.13199 7 6.99996 7h1.0001v-2.6h-1.0001c-2.42798 0-4.39995-1.972-4.39995-4.4 0-2.428 1.97197-4.4 4.39995-4.4h2.4041v13.6c0 1.436 1.1639 2.6 2.5999 2.6V11h-5.004ZM24.0038 25c3.868 0 6.9999-3.1361 6.9999-7 0-3.868-3.1319-7-6.9999-7h-1.0001v2.5999h1.0001c2.428 0 4.3999 1.9721 4.3999 4.4001s-1.9719 4.3999-4.3999 4.3999h-2.4041V8.79997c0-1.43602-1.1639-2.60002-2.5999-2.60002V25h5.004Z'/%3e%3c/svg%3e"
                                    alt="QuickBooks"
                                    style={{ height: 40, width: 40 }}
                                />
                            )}
                        </Box>
                        <Box style={{ backgroundColor: '#F9FFFF', borderRadius: '12px', padding: '12px 16px' }}>
                            <Typography>What’s the name of your business?</Typography>
                        </Box>
                    </Box>
                    <Box style={{ width: '30%', maxWidth: '80%', marginLeft: 'auto', backgroundColor: '#7CB5B5', borderRadius: '12px' }} p={1} m={2}>
                        <Typography variant="body1" align="left">{userInfo.businessName}</Typography>
                    </Box>
                </>
            )}

            {userInfo.email && (
                // Similar structure as name, with question and answer
                <>
                    <Box style={{ display: 'flex', gap: '12px', margin: '16px' }}>
                        <Box style={{ marginTop: '8px' }}>
                            {isParoWebsite ? (
                                <img src="https://expert-files-dev.s3.us-east-1.amazonaws.com/ParoGroupLogo.png" style={{ height: 40, width: 40, borderRadius: '50%' }} alt="Paro logo" />
                            ) : (
                                <img
                                    src="data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 36 36'%3e%3cpath fill='%232CA01C' d='M18 36c9.9411 0 18-8.0589 18-18 0-9.94114-8.0589-18-18-18C8.05886 0 0 8.05886 0 18c0 9.9411 8.05886 18 18 18Z'/%3e%3cpath fill='white' d='M12.0002 11c-3.86797 0-6.99996 3.136-6.99996 7 0 3.868 3.13199 7 6.99996 7h1.0001v-2.6h-1.0001c-2.42798 0-4.39995-1.972-4.39995-4.4 0-2.428 1.97197-4.4 4.39995-4.4h2.4041v13.6c0 1.436 1.1639 2.6 2.5999 2.6V11h-5.004ZM24.0038 25c3.868 0 6.9999-3.1361 6.9999-7 0-3.868-3.1319-7-6.9999-7h-1.0001v2.5999h1.0001c2.428 0 4.3999 1.9721 4.3999 4.4001s-1.9719 4.3999-4.3999 4.3999h-2.4041V8.79997c0-1.43602-1.1639-2.60002-2.5999-2.60002V25h5.004Z'/%3e%3c/svg%3e"
                                    alt="QuickBooks"
                                    style={{ height: 40, width: 40 }}
                                />
                            )}
                        </Box>
                        <Box style={{ backgroundColor: '#F9FFFF', borderRadius: '12px', padding: '12px 16px' }}>
                            <Typography>Could you share your email so we can follow up if needed?</Typography>
                        </Box>
                    </Box>
                    <Box style={{ width: '30%', maxWidth: '80%', marginLeft: 'auto', backgroundColor: '#7CB5B5', borderRadius: '12px' }} p={1} m={2}>
                        <Typography variant="body1" align="left">{userInfo.email}</Typography>
                    </Box>
                </>
            )}

            {/* Current question input */}
            {showUserForm && !showSummary && (
                <>
                    <Box style={{ display: 'flex', gap: '12px', margin: '16px' }}>
                        <Box style={{ marginTop: '8px' }}>
                            {isParoWebsite ? (
                                <img src="https://expert-files-dev.s3.us-east-1.amazonaws.com/ParoGroupLogo.png" style={{ height: 40, width: 40, borderRadius: '50%' }} alt="Paro logo" />
                            ) : (
                                <img
                                    src="data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 36 36'%3e%3cpath fill='%232CA01C' d='M18 36c9.9411 0 18-8.0589 18-18 0-9.94114-8.0589-18-18-18C8.05886 0 0 8.05886 0 18c0 9.9411 8.05886 18 18 18Z'/%3e%3cpath fill='white' d='M12.0002 11c-3.86797 0-6.99996 3.136-6.99996 7 0 3.868 3.13199 7 6.99996 7h1.0001v-2.6h-1.0001c-2.42798 0-4.39995-1.972-4.39995-4.4 0-2.428 1.97197-4.4 4.39995-4.4h2.4041v13.6c0 1.436 1.1639 2.6 2.5999 2.6V11h-5.004ZM24.0038 25c3.868 0 6.9999-3.1361 6.9999-7 0-3.868-3.1319-7-6.9999-7h-1.0001v2.5999h1.0001c2.428 0 4.3999 1.9721 4.3999 4.4001s-1.9719 4.3999-4.3999 4.3999h-2.4041V8.79997c0-1.43602-1.1639-2.60002-2.5999-2.60002V25h5.004Z'/%3e%3c/svg%3e"
                                    alt="QuickBooks"
                                    style={{ height: 40, width: 40 }}
                                />
                            )}
                        </Box>
                        <Box style={{ backgroundColor: '#F9FFFF', borderRadius: '12px', padding: '12px 16px' }}>
                            <Typography>{renderQuestion()}</Typography>
                        </Box>
                    </Box>
                    <Box style={{ padding: '0 16px', marginTop: '24px' }}>
                        <TextareaAutosize
                            value={currentInput}
                            onChange={(e) => setCurrentInput(e.target.value)}
                            placeholder={`Enter`}
                            className="w-full p-4 border border-gray-300 rounded mb-4"
                            minRows={4}
                            maxRows={8}
                            style={{ resize: 'none', width: '100%' }}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                    e.preventDefault();
                                    if (currentInput.trim()) {
                                        handleSubmit();
                                    }
                                }
                            }}
                        />
                        <div className="flex justify-end space-x-4 mb-4">
                            <Button
                                onClick={handleSubmit}
                                // @ts-ignore
                                label={<SendIcon fontSize='medium' /> as String}
                                size="md"
                                disabled={!currentInput.trim()}
                                color={!currentInput.trim() ? "disabled" : "success"}
                            />
                        </div>
                    </Box>
                </>
            )}

            {/* Summary section */}
            {showSummary && (
                <>
                    {/* Show all chat messages first */}
                    <Box style={{ display: 'flex', gap: '12px', margin: '16px' }}>
                        <Box style={{ marginTop: '8px' }}>
                            {isParoWebsite ? (
                                <img src="https://expert-files-dev.s3.us-east-1.amazonaws.com/ParoGroupLogo.png" style={{ height: 40, width: 40, maxWidth: 40 }} alt="Paro logo" />
                            ) : (
                                <img
                                    src="data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 36 36'%3e%3cpath fill='%232CA01C' d='M18 36c9.9411 0 18-8.0589 18-18 0-9.94114-8.0589-18-18-18C8.05886 0 0 8.05886 0 18c0 9.9411 8.05886 18 18 18Z'/%3e%3cpath fill='white' d='M12.0002 11c-3.86797 0-6.99996 3.136-6.99996 7 0 3.868 3.13199 7 6.99996 7h1.0001v-2.6h-1.0001c-2.42798 0-4.39995-1.972-4.39995-4.4 0-2.428 1.97197-4.4 4.39995-4.4h2.4041v13.6c0 1.436 1.1639 2.6 2.5999 2.6V11h-5.004ZM24.0038 25c3.868 0 6.9999-3.1361 6.9999-7 0-3.868-3.1319-7-6.9999-7h-1.0001v2.5999h1.0001c2.428 0 4.3999 1.9721 4.3999 4.4001s-1.9719 4.3999-4.3999 4.3999h-2.4041V8.79997c0-1.43602-1.1639-2.60002-2.5999-2.60002V25h5.004Z'/%3e%3c/svg%3e"
                                    alt="QuickBooks"
                                    style={{ height: 40, width: 40 }}
                                />
                            )}
                        </Box>
                        <Box style={{ backgroundColor: '#F9FFFF', borderRadius: '12px', padding: '12px 16px' }}>
                            <Box className="mb-2" style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                                <Typography variant="body1">
                                    <strong>Proposal Title: </strong>
                                </Typography>
                                <Typography variant="body1" style={{ marginLeft: '6px' }}>{generatedSummary["Proposal Title"]}</Typography>
                                <br />
                                <Typography variant="body1">
                                    <strong>Summary Proposal: </strong>
                                </Typography>
                                <Typography variant="body1" style={{ marginLeft: '6px' }}>{generatedSummary["Proposal Description"]}</Typography>
                                <br />
                                <Typography variant="body1">
                                    <strong>Required Financial Services Needed:</strong>{" "}
                                </Typography>
                                {generatedSummary["Required Services"].map((obj) => {
                                    return <Typography variant="body1" style={{ marginLeft: '6px' }}>{obj}</Typography>
                                })}
                                <br />
                                <Typography variant="body1">
                                    <strong>Required Skills:</strong>{" "}
                                </Typography>
                                {generatedSummary["Required Skills"].map((obj) => {
                                    return <Typography variant="body1" style={{ marginLeft: '6px' }}>{obj}</Typography>
                                })}
                            </Box>
                        </Box>
                    </Box>

                    <Box m={2}>
                        <Typography variant='body1' style={{ color: "#000", fontWeight: 'bold', justifySelf: 'center' }}>
                            Recommended Price:$200/hr
                        </Typography>
                        <Divider orientation='horizontal' />
                        <FreelancerSection
                            freelancers={freelancers}
                            isMatchLoading={loadingFls}
                            setActiveStage={setActiveStage}
                        />
                    </Box>
                </>
            )}
        </Box>
    );
};

export default ChatScreen;