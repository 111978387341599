import React, { useState } from 'react';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import WelcomeScreen from '../ClientEnhancementUI/WelcomeScreen';
import Navbar from '../ClientEnhancementUI/Navbar';


const SkillsContainer = styled.div`
     
    font-size: 1.3rem;
    line-height: 2.5rem;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    max-width: 1326px;
`;


const ModalOverlay = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContainer = styled.div`
  background: white;
  border-radius: 12px;
  position: relative;
  width: 100%;
  max-width: 900px;
  max-height: 90vh;
  overflow: auto;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
`;

const CloseButton = styled.button`
  position: absolute;
  right: 16px;
  top: 16px;
  background: transparent;
  border: none;
  color: #6B7280;
  cursor: pointer;
  padding: 8px;
  z-index: 10;
  
  &:hover {
    color: #111827;
  }
`;

const Container = styled.div`
    color: #060821 !important;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
    z-index: 2 !important;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    max-width: 1596px;
    position: relative !important;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
`;

const Row = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
    --bs-gutter-x: 3rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: -1rem;
    margin-left: -1rem;
    align-items: center;
`;

const Column = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
    max-width: 100%;
    margin-top: var(--bs-gutter-y);
    flex: 0 0 auto;
    width: 50%;
    padding-right: 1rem;
    padding-left: 1rem;
`;

const LeadspaceHeader = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
    flex-wrap: wrap !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center !important;
`;

const BannerContent = styled.article`
  padding-bottom: 0;
`;

const BannerTitle = styled.h1`
  font-size: 48px;
  color: #060821;
  font-weight: 300;
  line-height: 64px;
  padding-top: 0 !important;
  margin-bottom: 0;
`;

const BannerText = styled.p`
  font-size: 20px;
  line-height: 32px;
  color: #060821;
  font-weight: 500;
`;

const BannerLink = styled.a`
    margin: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
    transition: all 0.3s ease !important;
    position: relative;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    display: inline-block;
    color: #FFFFFF;
    padding: 12px 24px;
    background-color: #248384;
    text-decoration: none;
    border: 1px solid #248384;
    border-radius: 8px;
    text-transform: uppercase;
    top: 16px;
`;

const ImageContainer = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const BannerImage = styled.img`
  width: 460px;
  max-width: 460px;
  height: 100%;
`;

const SkillsResults = styled.section`
  padding: 100px 0;
  background: #fff;
    font-size: 1.3rem;
    line-height: 2.5rem;
    margin: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
    background-color: #D9EFEF;
    padding: 64px 0;
    margin-block-start: 0%;
    margin-block-end: 0;
`;

const Title = styled.div`
  text-align: center;
  max-width: 800px;
  margin: 0 auto 80px;

  h2 {
    font-size: 36px;
    font-weight: 500;
    color: #060821;
    margin-bottom: 24px;
    line-height: 43.2px;
    
  }

  p {
    font-size: 16px;
    line-height: 24px;
    color: #060821;
    text-align: center;
  }
`;

const SkillsRow = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  
  @media (max-width: 768px) {
    display: none;
  }
`;

const SkillCard = styled.div`
  padding: 24px;
  transition: all 0.3s ease;
  background-color: #F1F5F9;
  font-family: "Montserrat", sans-serif;
  border-radius: 12px;
  max-width: 424px;
`;

const IconContainer = styled.div`
  margin-bottom: 24px;
  
  img {
    width: 64px;
    height: 64px;
  }
`;

const CardTitle = styled.h3`
  font-size: 20px;
  font-weight: 500;
  color: #248384;
  margin-bottom: 12px;
  font-style: normal;
`;

const CardText = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: #060821;
  font-weight: 500;
  text-align: left;
  margin-bottom: 0;
  a {
    color: #248384;
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }
`;

const ParoWebsite = () => {
    const [showModal, setShowModal] = useState(false);

    const handleGetStarted = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        sessionStorage.clear();
    };

    return (
        <>
            <Navbar />
            <Container>
                <Row>
                    <Column>
                        <LeadspaceHeader>
                            <BannerContent>
                                <BannerTitle>
                                    Find Your Best-Fit Finance Expert in Minutes
                                </BannerTitle>
                                <BannerText>
                                    Tell us your goals, and our AI will quickly connect you with the best finance professionals
                                    to help you achieve them.
                                </BannerText>
                                <BannerLink onClick={handleGetStarted}>
                                    Tell Us What You Need
                                </BannerLink>
                            </BannerContent>
                        </LeadspaceHeader>
                    </Column>
                    <Column>
                        <ImageContainer>
                            <BannerImage
                                src="https://paro.ai/wp-content/uploads/2025/01/Platform-Hero-Image-x2.webp"
                                alt="Platform Hero Image"
                                title="Our Platform 1"
                            />
                        </ImageContainer>
                    </Column>
                </Row>
            </Container>
            <SkillsResults>
                <SkillsContainer>
                    <Title>
                        <h2>Smarter Matching. Stronger Results.</h2>
                        <p>
                            Paro's AI-powered platform makes finding top finance experts fast and seamless,
                            connecting your business with vetted professionals who meet your exact needs.
                        </p>
                    </Title>
                    <SkillsRow>
                        <SkillCard>
                            <IconContainer>
                                <img
                                    src="https://paro.ai/wp-content/uploads/2025/01/Group-23768-2.svg"
                                    alt="Effortless Precision"
                                />
                            </IconContainer>
                            <CardTitle>Effortless Precision</CardTitle>
                            <CardText>
                                Advanced algorithms identify the ideal finance and accounting experts to meet
                                your unique challenges.
                            </CardText>
                        </SkillCard>

                        <SkillCard>
                            <IconContainer>
                                <img
                                    src="https://paro.ai/wp-content/uploads/2025/01/Group-23769-2.svg"
                                    alt="Expertise You Can Trust"
                                />
                            </IconContainer>
                            <CardTitle>Expertise You Can Trust</CardTitle>
                            <CardText>
                                We only accept the top 2% of applicants, so you know they're{' '}
                                <a
                                    href="https://paro.ai/blog/how-we-vet-our-finance-talent/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    thoroughly vetted
                                </a>
                                , proven professionals.
                            </CardText>
                        </SkillCard>

                        <SkillCard>
                            <IconContainer>
                                <img
                                    src="https://paro.ai/wp-content/uploads/2025/01/Group-23769-3.svg"
                                    alt="Built For Growth"
                                />
                            </IconContainer>
                            <CardTitle>Built For Growth</CardTitle>
                            <CardText>
                                Get access to a network of experts who can scale with you to solve complex
                                challenges, improve operations and drive strategic results.
                            </CardText>
                        </SkillCard>
                    </SkillsRow>
                </SkillsContainer>
            </SkillsResults>

            {showModal && (
                <ModalOverlay onClick={(e) => e.target === e.currentTarget && handleCloseModal()}>
                    <ModalContainer>
                        <CloseButton onClick={handleCloseModal}>
                            <CloseIcon />
                        </CloseButton>
                        <div className="overflow-y-auto">
                            <WelcomeScreen onClose={handleCloseModal} isParoWebsite={true} />
                        </div>
                    </ModalContainer>
                </ModalOverlay>
            )}
        </>
    );
};

export default ParoWebsite; 