import React, { useEffect, useState } from 'react';
import { TextareaAutosize, Box, Typography } from "@material-ui/core";
import { Button } from "@paro.io/base-ui";
import ChatScreen from './ChatScreen';
import ThanksScreen from './ThanksScreen';
import SendIcon from '@material-ui/icons/Send';

interface WelcomeScreenProps {
    isParoWebsite?: boolean;
    onClose?: () => void;
}

const WelcomeScreen: React.FC<WelcomeScreenProps> = ({ onClose, isParoWebsite }) => {

    const [fieldText, setFieldText] = useState("");
    const [generatedSummary, setGeneratedSummary] = useState(null);
    const [loading, setLoading] = useState(false);
    const [activeStage, setActiveStage] = useState(0);

    useEffect(() => {
        if (activeStage === 0) {
            const data = sessionStorage.getItem("enh-proposal");
            if (data) {
                setGeneratedSummary(JSON.parse(data).generatedSummary);
            }
        }
    }, [activeStage]);

    useEffect(() => {
        const clearSessionStorage = () => {
            sessionStorage.clear();
        };

        window.addEventListener("beforeunload", clearSessionStorage);

        return () => {
            window.removeEventListener("beforeunload", clearSessionStorage);
        };
    }, []);

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const response = await fetch(
                "https://jmmeiy3gw7.execute-api.us-east-1.amazonaws.com/dev2/predictions",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ text: fieldText }),
                }
            );
            const data = await response.json();
            setGeneratedSummary(data);
            setActiveStage(activeStage + 1);
        } catch (error) {
            console.error("Error:", error);
        }
        setLoading(false);
    };

    const handleNext = () => {
        sessionStorage.setItem(
            "enh-proposal",
            JSON.stringify({ generatedSummary })
        );

        setActiveStage(activeStage + 1);
    };

    return (
        <div className="flex flex-col justify-center items-center py-4 px-8 bg-white rounded-lg">
            <Box style={{ position: 'relative' }}>
                {activeStage === 0 && (
                    <div>
                        <Box style={{ borderBottom: '3px solid #2ca01c', paddingBottom: '6px', width: '100%' }} m={2}>
                            <Typography variant="h4" align="center">Let's get to know your business</Typography>
                        </Box>
                        <Box ml={4} mb={2}>
                            <Typography variant="subtitle1">Share what you're looking for:</Typography>
                            <Box ml={4}>
                                <ul>
                                    <li><Typography variant="subtitle1">Financial services needed</Typography></li>
                                    <li><Typography variant="subtitle1">Primary goals</Typography></li>
                                    <li><Typography variant="subtitle1">Required skills/certifications</Typography></li>
                                    <li><Typography variant="subtitle1">Software used</Typography></li>
                                    <li><Typography variant="subtitle1">Experience level preferred</Typography></li>
                                    <li><Typography variant="subtitle1">Start date</Typography></li>
                                </ul>
                            </Box>
                            <Typography variant="subtitle1">The more details you share, the better we can find the right match for your unique requirements.</Typography>
                        </Box>
                        <TextareaAutosize
                            value={fieldText}
                            onChange={(e) => setFieldText(e.target.value)}
                            className="w-full p-4 border border-gray-300 rounded mb-4"
                            minRows={4}
                            maxRows={8}
                            style={{ resize: 'none' }}
                        />
                        <div className="flex justify-end space-x-4 mb-4">
                            <Button
                                onClick={handleSubmit}
                                // @ts-ignore
                                label={<SendIcon fontSize='medium' /> as String}
                                size="md"
                                isLoading={loading}
                                disabled={!fieldText}
                                color={!fieldText ? "disabled" : "success"}
                            />
                        </div>
                    </div>
                )}
                {activeStage === 1 &&
                    <ChatScreen
                        fieldText={fieldText}
                        generatedSummary={generatedSummary}
                        setGeneratedSummary={setGeneratedSummary}
                        activeStage={activeStage}
                        setActiveStage={setActiveStage}
                        handleNext={handleNext}
                        isParoWebsite={isParoWebsite}
                    />
                }
                {activeStage === 2 && (
                    <ThanksScreen
                        setActiveStage={setActiveStage}
                        setFieldText={setFieldText}
                        setGeneratedSummary={setGeneratedSummary}
                    />
                )}
            </Box>
            {activeStage !== 2 && (
                <div className="w-5/6 bg-gray-200 rounded-full h-2 mt-4">
                    <div
                        className="bg-gray-400 h-2 rounded-full transition-all duration-300"
                    // style={{
                    //     width: `${((activeStage + 1) / 3) * 100}%`,
                    // }}
                    ></div>
                </div>
            )}
        </div>
    );
};

export default WelcomeScreen;