import React, { useState } from "react";
import { Box, Typography, Divider, Chip } from "@material-ui/core";
import { Avatar } from "@paro.io/base-ui";
import { Spinner } from "react-bootstrap";
import { useSnackbar } from "notistack";
import { Rating } from "@material-ui/lab";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import styled from 'styled-components';

const CardContainer = styled.div`
  padding: 0.5rem;
  width: 100%;
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  height: 420px; // Fixed height for all cards
  display: flex;
  flex-direction: column;
`;

const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
  min-height: 32px; // Height of the Chip component
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  flex: 1;
`;

const FreelancerName = styled(Typography)`
  && {
    font-weight: bold;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 48px; // Fixed height for 2 lines
    line-height: 1.2;
    margin: 8px 0;
  }
`;

export const SimplifiedAiResultCard = ({
  freelancer,
  onViewReview,
  cart,
  setCart,
  index
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [isAddCartLoading, setAddCartLoading] = useState(false);

  const onChangeHandler = async (freelancer) => {
    setAddCartLoading(true);
    if (!cart.some((c) => c.id === freelancer.id)) {
      setCart((curr) => {
        return [...curr, { ...freelancer, flID: freelancer.id }];
      });

      sessionStorage.setItem(
        "CartFreelancers",
        JSON.stringify([...cart, { ...freelancer, flID: freelancer.id }])
      );

      enqueueSnackbar(`Added ${freelancer.name}  to Cart!`, {
        variant: "success",
      });
    } else {
      setCart((currentState) =>
        currentState.filter((c) => c.id !== freelancer.id)
      );
      const currentCart =
        JSON.parse(sessionStorage.getItem("CartFreelancers")) || [];
      const updatedCart = currentCart.filter((c) => c.id !== freelancer.id);
      sessionStorage.setItem("CartFreelancers", JSON.stringify(updatedCart));

      enqueueSnackbar(`Removed ${freelancer.name} from Cart!`, {
        variant: "warning",
      });
    }
    setAddCartLoading(false);
  };

  const isSelected = cart.some((c) => c.id === freelancer.id);

  return (
    <CardContainer
      onClick={() => {
        onChangeHandler({
          id: freelancer.id,
          name: freelancer.FL_Name,
          serviceLine: freelancer.FL_Service,
          imageUrl: freelancer.imageUrl,
        });
      }}
    >
      <CardHeader>
        <div>{isSelected && <CheckCircleOutlineIcon fontSize="small" style={{ color: '#248384' }} />}</div>
        <div>
          {index === 0 && <Chip label="Best Fit" style={{ border: '2px solid #248384', color: '#060821', backgroundColor: '#EEFFFF' }} />}
        </div>
      </CardHeader>

      <CardContent>
        {isAddCartLoading ? (
          <Spinner animation="grow" size="sm" className="text-success ml-3 mr-3.5" />
        ) : null}

        <Avatar name="Avatar Name" size="md" src={freelancer.imageUrl} />

        <div className="flex flex-col gap-0 ml-2 w-full">
          <div
            onClick={(e) => {
              e.stopPropagation();
              window.open(
                `${process.env.REACT_APP_PARO_APP_URL}/public/${freelancer?.id}`,
                "_blank"
              );
            }}
          >
            <FreelancerName variant="subtitle1" align="center">
              {freelancer?.FL_Name || freelancer?.name}
            </FreelancerName>
          </div>

          <Typography variant="body2" align="center" style={{ marginTop: '6px' }}>
            {freelancer?.FL_Service || freelancer?.serviceLine}
          </Typography>

          {freelancer.overallRatingAvg > 0 && (
            <div className="flex items-center justify-center gap-2">
              <Rating
                name="overallRatingAvg"
                defaultValue={freelancer.overallRatingAvg ?? 0}
                readOnly
                precision={0.1}
              />
              <Typography>
                {freelancer.overallRatingAvg ?? 0}
                {freelancer.overallRatingAvg > 0 && ` (${freelancer.ratings.length})`}
              </Typography>
            </div>
          )}

          {freelancer?.legacyMetadata?.hourlyRate && (
            <Typography variant="body2" align="center" style={{ color: '#248384', marginTop: '6px' }}>
              ${freelancer?.legacyMetadata?.hourlyRate} / hour
            </Typography>
          )}

          <Box m={2}><Divider orientation='horizontal' /></Box>

          <Typography variant="subtitle1" align="left"><strong>Strengths</strong></Typography>
          <Box display="flex" flexDirection={'row'} gridGap={2} alignItems={"center"}>
            <CheckCircleOutlineIcon fontSize="small" style={{ color: '#248384' }} />
            <Typography variant="body2" align="left">Top Expert</Typography>
          </Box>
          <Box display="flex" flexDirection={'row'} gridGap={2} alignItems={"center"} my={1}>
            <CheckCircleOutlineIcon fontSize="small" style={{ color: '#248384' }} />
            <Typography variant="body2" align="left">Industry Match</Typography>
          </Box>
          <Box display="flex" flexDirection={'row'} gridGap={2} alignItems={"center"}>
            <CheckCircleOutlineIcon fontSize="small" style={{ color: '#248384' }} />
            <Typography variant="body2" align="left">Performance Fit</Typography>
          </Box>
        </div>
      </CardContent>
    </CardContainer>
  );
};
