import React from 'react';
import { Box, Typography, Grid } from "@material-ui/core";
import { Button } from "@paro.io/base-ui";
import { IconUser } from "@paro.io/base-icons";

const Navbar = () => {
    return (
        <Box style={{
            width: '100%',
            backgroundColor: '#060821',
            padding: '12px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            boxSizing: 'border-box',
            position: 'sticky',
            top: 0,
            zIndex: 99,
            height: '64px',
        }}>
            <img src='https://app.paro.io/assets/ParoLogo.svg' width='90' alt="Paro Logo" onClick={() => window.location.href = "https://paro.ai/"} className="cursor-pointer" />
            <Grid container item direction='row' justifyContent="flex-end" alignItems='center'>
                <Typography variant="caption" style={{ color: '#FFFFFF', marginRight: '12px' }}>BUSINESS SOLUTIONS</Typography>
                <Typography variant="caption" style={{ color: '#FFFFFF', marginRight: '12px' }}>BECOME AN EXPERT</Typography>
                <Typography variant="caption" style={{ color: '#FFFFFF', marginRight: '12px' }}>OUR PLATFORM</Typography>
                <Typography variant="caption" style={{ color: '#FFFFFF', marginRight: '12px' }}>RESOURCES</Typography>
                <Button className="text-black bg-warning" label="Get Started" size="sm" />
                <Typography variant="caption" style={{ color: '#FFFFFF', marginLeft: '12px', cursor: 'pointer' }}><div className='flex flex-row'><IconUser size="xs" />&nbsp;Log In</div></Typography>
            </Grid>
        </Box>
    )
}

export default Navbar;