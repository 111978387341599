import React from 'react';
import { Typography, Grid, makeStyles, IconButton, Box } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  calendar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  container: {
    boxShadow: '0 6px 6px rgba(0, 0, 0, 0.1)',
    border: '1px solid #DEE2E6',
    borderRadius: '12px'
  },
  day: {
    width: '43px',
    textAlign: 'center',
    padding: '6px',
    cursor: 'pointer',
    background: 'linear-gradient(to bottom, #F1F5F9 40%, white 40%)',
  },
  selectedDay: {
    background: '#248384',
    color: 'white'
  },
  navButton: {
    padding: theme.spacing(1),
    borderRadius: '4px',
    cursor: 'pointer',
    boxShadow: '0 6px 6px rgba(0, 0, 0, 0.1)',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  borderRadiusRight: {
    borderRadius: '0px 12px 12px 0px'
  },
  borderRadiusLeft: {
    borderRadius: '12px 0px 0px 12px'
  }
}));

interface CalendarProps {
  selectedDate: Date;
  setSelectedDate: (selectedDate: Date) => void;
  currentDate: Date;
  setCurrentDate: (currentDate: Date) => void;
};

const Calendar: React.FC<CalendarProps> = ({ selectedDate, setSelectedDate, currentDate, setCurrentDate }) => {
  const classes = useStyles();


  const getWeekDates = (date: Date): Date[] => {
    const result: Date[] = [];

    const startDate = new Date(date);
    startDate.setHours(0, 0, 0, 0);

    for (let i = 0; i < 7; i++) {
      const nextDay = new Date(startDate);
      nextDay.setDate(startDate.getDate() + i);
      result.push(nextDay);
    }

    return result;
  };

  const handlePreviousWeek = () => {
    const newDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 7);
    if (newDate < currentDate) {
      setCurrentDate(newDate);
    } else {
      setCurrentDate(new Date());
    }
  };

  const handleNextWeek = () => {
    const newDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 7);
    setCurrentDate(newDate);
  };

  const handleDateSelect = (date: Date) => {
    setSelectedDate(date);
  };

  const formatDate = (date: Date) => {
    const weekday = new Intl.DateTimeFormat('default', { weekday: 'short' }).format(date);
    const day = new Intl.DateTimeFormat('default', { day: 'numeric' }).format(date);
    const month = new Intl.DateTimeFormat('default', { month: 'short' }).format(date);

    return `${weekday} ${day} ${month}`;
  };

  const weekDates = getWeekDates(currentDate);
  const isToday = new Date(weekDates[0]).toDateString() === new Date().toDateString();

  return (
    <div className={classes.root}>
      <Typography variant="h6" className={classes.title} align='center'>
        {currentDate.toLocaleString('default', { month: 'long', year: 'numeric' })}
      </Typography>
      <Grid container justify="space-between" alignItems="center">
        <Box>
          <IconButton className={classes.navButton} onClick={handlePreviousWeek} disabled={isToday}>
            <ArrowBackIcon fontSize="small" />
          </IconButton>
        </Box>
        <Box>
          <Grid container justify="space-between" className={classes.container}>
            {weekDates.map((date, index) => (
              <Grid
                item
                key={index}
                className={`${classes.day} ${selectedDate && date.toDateString() === selectedDate.toDateString() ? classes.selectedDay : ''} ${index === 0 && classes.borderRadiusLeft} ${index === 6 && classes.borderRadiusRight}`}
                onClick={() => handleDateSelect(date)}
              >
                <Typography variant="body1">
                  {formatDate(date)}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box>
          <IconButton className={classes.navButton} onClick={handleNextWeek}>
            <ArrowForwardIcon fontSize="small" />
          </IconButton>
        </Box>
      </Grid>
    </div>
  );
};

export default Calendar;
