import React, { useContext, useState } from "react";
import { Text, Modal, Heading } from "@paro.io/base-ui";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import { formatDateAsMDY } from "../../utils/dateService";
import { Spinner } from "react-bootstrap";
import AiResultCard from "./AiResultCart";
import { Button as BaseUiButton } from "@paro.io/base-ui";
import { CartContext } from "../CartContext";
import { useHits } from "react-instantsearch";
import ReviewsModal from "./ReviewsModal";

const AiSearchResults = ({
  freelancers,
  isMatchLoading,
  proposalId,
  setShowCompareAll,
  setCompareType,
}) => {
  const [viewProfile, setViewProfile] = useState(false);
  const [viewRating, setViewRating] = useState(false);
  const [ratingData, setRatingData] = useState({});

  const [selectedProfile, setSelectedProfile] = useState(null);
  const [compareFls] = useContext(CartContext).compareFlsContext;
  const { hits } = useHits();

  if (isMatchLoading) {
    return (
      <div className="border py-12 rounded-md flex items-center justify-center flex-col gap-4 bg-white my-2">
        <Spinner animation="border" variant="success" />
        <Text>Fetching Freelancer Matches</Text>
      </div>
    );
  }

  const matchedFreelances = freelancers.map((freelancer) => {
    const hit = hits.find((hit) => hit.freelancerId === freelancer.id);
    if (hit) {
      return {
        ...freelancer,
        ratings: hit.ratings ?? [],
        overallRatingAvg: hit.overallRatingAvg ?? 0,
        rates: hit.rates ?? [],
      };
    }
    return freelancer;
  });

  return (
    <>
      <div className="flex items-center justify-between  gap-2  flex-wrap">
        <div className="flex flex-wrap gap-2 items-center">
          <h3 className="font-2xl text-success font-bold ">
            <WbSunnyIcon className="font-lg text-success font-bold" />{" "}
            {matchedFreelances.length}{" "}
            {matchedFreelances.length === 1 ? "Expert" : "Experts"} Recommended
          </h3>{" "}
          <p className="text-grey text-sm ">
            Based on Availability and AI recommendation engine
          </p>
        </div>
        <div className="text-right flex gap-2 justify-end w-fit">
          <span id="mm-compare-all" className="right">
            <BaseUiButton
              onClick={() => {
                setCompareType("selected");
                setShowCompareAll(true);
              }}
              disabled={compareFls.length === 0}
              className="mr-2 my-3"
              color="primary"
              label={`COMPARE [${compareFls.length}]`}
            />
            <BaseUiButton
              onClick={() => {
                setCompareType("all");
                setShowCompareAll(true);
              }}
              disabled={matchedFreelances.length === 0}
              className="mr-2 my-3"
              color="primary"
              label="COMPARE ALL"
              iconRight={<WbSunnyIcon />}
            />
          </span>
        </div>
      </div>
      {matchedFreelances.length > 0 ? (
        <div
          className="flex gap-4 !overflow-y-hidden"
          style={{ overflowY: "hidden" }}
        >
          {matchedFreelances.map((freelancer, idx) => (
            <AiResultCard
              freelancer={freelancer}
              key={idx}
              onViewProfile={(profile) => {
                setViewProfile(!viewProfile);
                setSelectedProfile(profile);
              }}
              proposalId={proposalId}
              onViewReview={(data) => {
                setViewRating(!viewRating);
                setRatingData(data);
              }}
            />
          ))}
        </div>
      ) : (
        <Text className="border p-4 rounded-md bg-white my-2">
          No Matches Found
        </Text>
      )}

      {/* Reviews Modal */}
      <Modal
        label=""
        variant={"passive"}
        color="primary"
        open={viewRating}
        onClose={() => setViewRating(false)}
        icon="danger"
        size="md"
      >
        <ReviewsModal freelancer={ratingData} />
      </Modal>

      {/* Expert profile Modal */}
      <Modal
        label=""
        variant={"passive"}
        color="primary"
        open={viewProfile}
        onClose={() => setViewProfile(false)}
        icon="danger"
        size="md"
      >
        <div className="mb-4">
          <Heading size="h5">Recent Experience</Heading>
          <div
            className="my-2 font-normal pl-4"
            dangerouslySetInnerHTML={{
              __html: selectedProfile?.Recent_Experience || "Missing Data",
            }}
          ></div>
        </div>
        <div className="mb-4">
          <Heading size="h5">Profile Notes</Heading>
          <div className="my-2">
            {selectedProfile?.Vetting_Notes.map((noteObj) => (
              <div key={noteObj.id} className="my-2">
                <div className="flex gap-2 text-sm text-grey">
                  <p className="mb-1">{`${formatDateAsMDY(
                    noteObj.timestamp
                  )}`}</p>{" "}
                  {" - "}
                  <p className="font-bold">{noteObj.accountManager.name}</p>
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: noteObj.note || "Missing Data",
                  }}
                ></div>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AiSearchResults;
