import React from "react";
import { Box } from "@material-ui/core";
import QuickBooksLive from "../components/QuickBooksLive/QuickBooksLive";
const QuickBooks = () => {

    return (
        <Box>
            {/* <Navbar /> */}

            {/* <div className="flex flex-row w-full bg-white h-full">

            </div> */}
            <QuickBooksLive />

        </Box>
    );
};

export default QuickBooks;
